<template>
    <div class="login">
        <div class="container">

            <LogoTop />

            <div class="row">
                <div class="col-sm-6 col-lg-8 offset-lg-2 form-cover">
                    <h1 class="welcome-h1 text-center">Create your account</h1>
                    <p class="text-center"><b>Please enter your credentials correctly to continue.</b></p>

                    <form class="login-form mt-5 reg-form" autocomplete="off" method="post" v-on:submit.prevent="handleRegistration">
                        <div class="row">
                            <div class="col-lg-6">
                                <div class="form-group">
                                    <span>Customer Code</span>
                                    <input type="text" class="form-control" placeholder="Customer Code" v-model="register.vendor_id" required>
                                    <i class="far fa-id-badge"></i>
                                </div>
                            </div>
                            <div class="col-lg-6">
                                <div class="form-group">
                                    <span>Username</span>
                                    <input type="text" class="form-control" placeholder="Username" v-model="register.username" required>
                                    <i class="far fa-user"></i>
                                </div>
                            </div>

                            <div class="col-lg-6">
                                <div class="form-group">
                                    <span>Security Question 1</span>

                                    <select type="text" class="form-control select" v-model="register.security_question1" required>
                                        <option selected value="" disabled>Security Question 1</option>
                                        <option :class="register.security_question2 == q.ID ? 'd-none' : ''" v-for="q in security_questions" :key="q.ID" :value="q.ID">{{q.question}}</option>
                                    </select>

                                    <i class="fa fa-fingerprint"></i>
                                </div>
                            </div>
                            
                            <div class="col-lg-6">
                                <div class="form-group">
                                    <span>Your Answer to Question 1</span>
                                    <input type="text" class="form-control" placeholder="Your Answer to  Question 1" v-model="register.security_answer1" required>
                                    <i class="fa fa-user-shield"></i>
                                </div>
                            </div>

                            <div class="col-lg-6">
                                <div class="form-group">
                                    <span>Security Question 2</span>

                                    <select type="text" class="form-control select" v-model="register.security_question2" required>
                                        <option selected="" value="" disabled>Security Question 2</option>
                                        <option :class="register.security_question1 == q.ID ? 'd-none' : ''" v-for="q in security_questions" :key="q.ID" :value="q.ID">{{q.question}}</option>
                                    </select>
                                    <i class="fa fa-fingerprint"></i>
                                </div>
                            </div>

                            <div class="col-lg-6">
                                <div class="form-group">
                                    <span>Your Answer to Question 2</span>
                                    <input type="text" class="form-control" placeholder="Your Answer to Question 2" v-model="register.security_answer2" required>
                                    <i class="fa fa-user-shield"></i>
                                </div>
                            </div>
                            

                            <div class="col-12">
                                <hr>
                            </div>


                            <div class="col-lg-6">
                                <div class="form-group">
                                    <span>Password</span>
                                    <input :type="passwordFieldType" class="form-control" placeholder="Password" v-model="register.password1" required  minlength="8" @keyup="CheckPassword(register.password1)">
                                    <i @click="passwordFieldType == 'password' ? passwordFieldType = 'text' : passwordFieldType = 'password'" :class="passwordFieldType == 'password' ? 'far fa-eye' : 'fa fa-eye-slash'"></i>
                                    
                                    <small class="small-hint" :class="pass_stat == 'ok' ? 'text-success bolder' : 'text-danger'">{{pass_policy}}</small>
                                </div>
                            </div>
                            <div class="col-lg-6">
                                <div class="form-group">
                                    <span>Confirm Password</span>
                                    <input :type="passwordFieldType2" class="form-control" placeholder="Confirm Password" v-model="register.password2" required>
                                    <i @click="passwordFieldType2 == 'password' ? passwordFieldType2 = 'text' : passwordFieldType2 = 'password'" :class="passwordFieldType2 == 'password' ? 'far fa-eye' : 'fa fa-eye-slash'"></i>
                                </div>
                            </div>

                            <div class="col-lg-6">
                                <div class="form-group">
                                    <span>Security PIN</span>
                                    <input type="password" class="form-control" placeholder="Security PIN" oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1');" minlength="4" maxlength="4" v-model="register.pin1" required>
                                    <i class="fa fa-key"></i>
                                </div>
                            </div>
                            <div class="col-lg-6">
                                <div class="form-group">
                                    <span>Confirm Security PIN</span>
                                    <input type="password" class="form-control" placeholder="Confirm Security PIN" oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1');" minlength="4" maxlength="4" v-model="register.pin2" required>
                                    <i class="fa fa-key"></i>
                                </div>
                            </div>
                        </div>

                        <div class="col-lg-8 offset-lg-2" :class="msg_status" v-html="msg_content"></div>

                        <button type="submit" class="btn login-btn col-lg-6 offset-lg-3 col-12" :disabled='isDisabled'>{{processBtn}}</button>

                        <div class="text-center mt-3">
                            <p class="font-14">Already have an account? <a href="/">Login</a></p>
                        </div>
                    </form>

                </div>
            </div>
                
            <Copyright />
        </div>
    </div>
</template>




<script>
import LogoTop from '@/components/LogoTop.vue';
import Copyright from '@/components/Copyright.vue';
import axios from 'axios';

export default {
    name: "Registration",
    components: {
        LogoTop, Copyright
    },
    
    data() {
        return{
            passwordFieldType:  'password',
            passwordFieldType2: 'password',
            isDisabled: false,
            processBtn: 'REGISTER',
            msg_status: '',
            msg_content: '',
            register:{
                security_question1:'',
                security_question2:'',
            },
            pass_policy:"",
            pass_stat:"",
            security_questions:[
                {
                    "ID":1,
                    "question":'What is the name of your favorite pet?'
                },
                {
                    "ID":2,
                    "question":'In what city were you born?'
                },
                {
                    "ID":3,
                    "question":'What is the name of your first school?'
                },
                {
                    "ID":4,
                    "question":'What is the name of your favorite Actor?'
                },
                {
                    "ID":5,
                    "question":"What is your mother's maiden name?",
                },
                {
                    "ID":6,
                    "question":'What is the name of your favorite Cousin?'
                },
                {
                    "ID":7,
                    "question":'What was the make of your first car?'
                },
                {
                    "ID":8,
                    "question":'What is your favorite color?'
                },
                {
                    "ID":9,
                    "question":'What City was your high school located?'
                }
            ],
        }
    },

    watch: {
        $route: {
            immediate: true,
            handler() {
                document.title = "FMN E-Commerce | Registration Page";
            },
        },
    },

    methods: {
        async handleRegistration () {
            try {
                this.msg_status     = '';
                this.msg_content    = '';
                
                if(this.register.password1 == this.register.password2){
                    
                    let patt = new RegExp(/[ `!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?~]/);
                        
                    if(patt.test(this.register.username)){
                        this.msg_status     = 'alert alert-danger mt-4';
                        this.msg_content    = "Username cannot contain special character and white space.";
                        return;
                    }

                    if(this.register.pin1 != this.register.pin2){
                        this.msg_status     = 'alert alert-danger mt-4';
                        this.msg_content    = "Pin & Confirm Pin did not match.";
                        return;
                    }
                    if(this.pass_stat != 'ok'){
                        this.msg_status     = 'alert alert-danger mt-4';
                        this.msg_content    = "Password should be minimum of 8 digits with 1 numeric and 1 special character .";
                        return;
                    }




                
                    this.isDisabled = true,
                    this.processBtn = 'Processing...';

                    await axios({
                        method: "post",
                        url: this.$hostname + "/register",
                        data: this.register,
                        headers: {"Content-Type": "application/json"},
                    })

                    .then(res => {
                        this.response = res.data;
                        
                        if(this.response.status){

                            this.msg_status     = 'alert alert-success mt-4';
                            this.msg_content    = this.response.message;
                            this.processBtn     = 'Loading...';
                        
                            
                            this.$store.commit("set_phone", this.response.phone);
                            this.$store.commit("set_vendor_no", this.response.vendor_no);
                            this.$store.commit("set_email", this.response.emailaddress);

                            // open Otp view;
                            this.$router.push('/otp-validation');

                        }
                        else{
                            this.msg_status     = 'alert alert-danger mt-4';
                            this.msg_content    = this.response.message;                       
                            this.isDisabled     = false;
                            this.processBtn     = 'REGISTER';
                        }                        
                    })

                    .catch(error => {
                        console.log(error)
                    })  

                    .finally(e => {                                       
                        this.isDisabled = false;
                        this.processBtn = 'REGISTER';
                        console.log(e);
                    })
                }
                else{
                    this.msg_status     = 'alert alert-danger mt-4';
                    this.msg_content    = "Password & Confirm Password did not match.";
                }
            }
            catch (err) {
                this.msg_status     = 'alert alert-danger mt-4';
                this.msg_content    = err;
            }            
        },

        CheckPassword(x) {
            if(x.match(/^(?=.*[0-9])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{8,}$/)){ 
                this.pass_policy    = "Password strength: Strong";
                this.pass_stat      = "ok"
            }
            else {             
                this.pass_policy    = "Minimum of 8 digits with 1 numeric and 1 special character.";
                this.pass_stat      = ""
            }
        },
    },

};
</script>