<template>
    <div class="dashboard">

        <SideNav v-bind:page_active="page_active" />

        <div class="main-section">

            <TopNav />

            <div class="content-body">
                <div class="row breadcrumb-area">
                    <div class="col-lg-6">
                        <h6><a href="/" title="Go back to profile"><i class="fa fa-arrow-left text-dark"></i></a> &nbsp;&nbsp;&nbsp;&nbsp;  <b>Track Order</b></h6>
                    </div>

                    <div class="col-lg-6">
                        <nav aria-label="breadcrumb">
                            <ol class="breadcrumb">
                                <li class="breadcrumb-item"><a href="/dashboard">Home</a></li>
                                <li class="breadcrumb-item"><a href="/orders">Orders</a></li>
                                <li class="breadcrumb-item active" aria-current="page">Track Order</li>
                            </ol>
                        </nav>                   
                    </div>
                </div>

                <dicv class="row mt-5 mb-5">
                    <div class="col">
                    <iframe class="border shadow-sm" src="https://www.google.com/maps/embed?pb=!1m14!1m12!1m3!1d253689.28269587999!2d3.773833284115146!3d6.534937802332875!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!5e0!3m2!1sen!2sng!4v1625842290974!5m2!1sen!2sng" width="100%" height="450" style="border:0;" allowfullscreen="" loading="lazy"></iframe>
                                        </div>

                </dicv>



                <Copyright />
            </div>
        </div>
    </div>
</template>




<script>
import SideNav from '@/components/SideNav.vue';
import Copyright from '@/components/Copyright.vue';
import TopNav from '@/components/TopNav.vue';



export default {
    name: "TrackingOrder",
    components: {
        SideNav, TopNav, Copyright
    },
    
    data() {
        return{
            page_active:"orders"
        }
    },

    watch: {
        $route: {
            immediate: true,
            handler() {
                document.title = "FMN | Dashboard";
            },
        },
    },

    mounted () {        
        this.renderChart(this.chartdata, this.options)
    },

    methods: {

    },

    created () {
    }
};
</script>

<style scoped>

</style>

