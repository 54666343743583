<template>
    <div class="sidenav-container" id="side_nav">
        <div class="side-nav-bg-cover"></div>

        <div class="side-nav-section">
            <div class="side-nav">
                <div class="row">
                    <div class="col">
                        <div class="d-flex justify-content-center pt-2" style="border-bottom: 1px solid #0f774b; padding-bottom:10px;background:#04633b;">
                            <div>
                                
                                <img src="@/assets/images/logo-sm.svg" style="width: 100%;">
                                <!-- <img src="@/assets/images/fmn-logo.png"> -->
                            </div>
                        </div>
                    </div>
                </div>

                <div class="row">
                    <div class="col-lg-12">
                        <ul>
                            <li style="height:0vh"></li>
                            <li id="first_li" :class="page_active == 'dashboard' ? 'side-link-before' : ''"></li>

                            <li :class="page_active == 'dashboard' ? 'side-link active' : page_active == 'profile' ? 'side-link-before' : ''" data-bs-toggle="tooltip" data-bs-placement="right" title="Dashboard">
                                <a href="/home">
                                    <i class="fa fa-home"></i>
                                </a>
                            </li>

                            <li :class="page_active == 'profile' ? 'side-link active' : page_active == 'contact' ? 'side-link-before' : page_active == 'dashboard' ? 'side-link-after' : ''" title="Profile" data-bs-toggle="tooltip" data-bs-placement="right">
                                <a href="/view-profile">
                                    <svg width="18" height="18" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg"> <path d="M10 0.699951C4.48 0.699951 0 5.17995 0 10.7C0 16.22 4.48 20.7 10 20.7C15.52 20.7 20 16.22 20 10.7C20 5.17995 15.52 0.699951 10 0.699951ZM10 3.69995C11.66 3.69995 13 5.03995 13 6.69995C13 8.35995 11.66 9.69995 10 9.69995C8.34 9.69995 7 8.35995 7 6.69995C7 5.03995 8.34 3.69995 10 3.69995ZM10 17.9C7.5 17.9 5.29 16.62 4 14.68C4.03 12.69 8 11.6 10 11.6C11.99 11.6 15.97 12.69 16 14.68C14.71 16.62 12.5 17.9 10 17.9Z" fill="white" /> </svg>
                                </a>
                            </li>

                            <li :class="page_active == 'contact' ? 'side-link active' : page_active == 'profile' ? 'side-link-after' : page_active == 'profile' ? 'side-link-after' : ''" title="Contact" data-bs-toggle="tooltip" data-bs-placement="right">
                                <a href="/contact-us">
                                    <svg width="18" height="18" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">  <path d="M18 0H2C0.9 0 0.00999999 0.9 0.00999999 2L0 20L4 16H18C19.1 16 20 15.1 20 14V2C20 0.9 19.1 0 18 0ZM11 12H9V10H11V12ZM11 8H9V4H11V8Z" fill="white" />  </svg>
                                </a>
                            </li>

                            <!-- <li class="side-link" title="Support">
                                <a href="/">
                                    <i class="fa fa-cogs"></i>
                                </a>
                            </li>

                            <li class="side-link" title="Contact Us">
                                <a href="/">
                                    <i class="fa fa-phone"></i>
                                </a>
                            </li> -->

                            <li :class="page_active == 'contact' ? 'side-link-after' : ''" title="Logout">
                                <a href="/">
                                    <i class="fa fa-power-off"></i>
                                </a>
                            </li>

                            <li id="last_li"></li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>

    </div>
</template>

<script>
export default {
    name: "DashboardNav",
    props: {
        page_active: String,
    },
};


</script>

<style scoped>

ul {padding-left: 10px;margin-bottom: 0;}
.side-nav-bg-cover {width: 30px; left: 0px;}
.side-nav {width: 80px;}
.side-link.active > a {padding: 0 5px 0 25px;}
.side-nav ul li a {color: #fff;display: block;padding: 0 5px 0px 25px;}
.main-section {width: calc(100% - 80px);margin-left: 80px;background-position: 45% 70%;}
.side-link-after {padding: 15px 0px 20px !important;}
.side-link.active, .container-fluid {background: #f6f6f6 !important;}
</style>