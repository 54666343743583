<template>

    <!-- change Profile -->
    <div class="row mt-3">
        <div class="col-lg-9" v-if="page==1">
            <div class="card shadow-sm mb-5">
                <div class="card-body">

                    <!-- page lead... -->
                    <h5 class="d-flex justify-content-between">
                        <b>Personal Information</b>
                        <!-- <a href="#!" class="bold font-12 black-text btn bg-secondary">
                            <i class="fa fa-pen"></i> <b>Edit</b>
                        </a> -->
                    </h5>

                    <!-- profile body -->
                    <div class="d-flex justify-content-start mt-3">
                        <!-- <div>
                            <div style="height:200px; width:200px; background:#f2f2f2;">

                            </div>

                            <p class="mt-2 text-center">
                                <a href="/sub-profile" class="color-scope" style="text-decoration:underline;"><b>Switch Profile</b></a>
                            </p>
                        </div> -->

                        <div class="mt-4" style="">
                            <h5>{{subacct_name}}</h5>
                            <h5><b>{{subacct_id}}</b></h5>
                            <h6>{{subacct_email}}</h6>
                            <h5>{{subacct_telephone}}</h5>
                        </div>
                    </div>

                    
                    <!-- page lead... -->
                    <h5 class="d-flex justify-content-between mt-5">
                        <b>Delivery Address</b>
                        <!-- <a href="#!" class="bold font-12 black-text btn bg-secondary">
                            <i class="fa fa-pen"></i> <b>Add Address</b>
                        </a> -->
                    </h5>

                    <div class="row">
                        <div class="col-lg-6" v-for="(x,i) in addresses" :key="i">
                            <div class="card mt-3">
                                <div class="card-body" :class="freightedid == i ? 'active' : ''" @click="freightedid = i" style="min-height:120px">
                                    <div class="row">
                                        <div class="col-8">
                                            <b>{{subacct_name}}</b> - 
                                            <b>{{subacct_id}}</b>
                                        </div>

                                        <div class="col-4 card-edit">
                                            <!-- <a href="/edit-address">
                                                <b><i class="fa fa-pen"></i> Edit</b>
                                            </a> -->
                                        </div>
                                    </div>

                                    <p class="mt-2 font-12" style="font-weight: 500;">
                                        <span>{{x.street}}, {{x.city}}, {{x.state}}</span>
                                    </p>

                                    <div class="font-14"  style="font-weight: 500;">{{subacct_telephone}}</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <!-- change password -->
        <div class="col-lg-9" v-if="page==2">
            <div class="card shadow-sm">
                <div class="card-header">
                    <b>Change Password</b>
                </div>
                <div class="card-body">
                    <form  class="login-form" autocomplete="off" method="post"  v-on:submit.prevent="handlePassword">
                        <div class="row" v-if="password_screen==0">
                            <div class="col-lg-6">
                                <div class="form-group">
                                    <b>Old Password:</b>
                                    <input type="password" class="form-control" v-model="reset.oldpassword" placeholder="Old Password" required />
                                </div>
                            </div>

                            <div class="col-lg-6">
                                <div class="form-group">
                                    <b>New Password:</b>
                                    <input type="password" class="form-control" v-model="reset.password1" required placeholder="New Password" minlength="8" @keyup="CheckPassword(reset.password1)">

                                    
                                    <small class="small-hint" :class="pass_stat == 'ok' ? 'text-success bolder' : 'text-danger'">{{pass_policy}}</small>
                                </div>
                            </div>

                            <div class="col-lg-6">
                                <div class="form-group">
                                    <b>Confirm New Password:</b>
                                    <input type="password" class="form-control" v-model="reset.password2" required placeholder="Confirm Password" minlength="8">
                                </div>
                            </div>

                            
                            <div class="col-lg-6 offset-lg-3">
                                <div :class="msg_status" v-html="msg_content"></div>
                                <button type="button" class="btn form-control login-btn"  @click="checkOldPassword();">Proceed</button>
                            </div>
                        </div>

                        <div class="row" v-if="password_screen==1">
                            <div class="col-lg-12">
                                <button @click="password_screen=0" class="btn btn-danger font-12"><i class="fa fa-arrow-left"></i> &nbsp;&nbsp;&nbsp; Back</button>
                            </div>

                            <div class="col-lg-6">
                                <div class="form-group">
                                    <b>Security Question 1</b>

                                    <select type="text" class="form-control select" v-model="reset.security_question1" disabled>
                                        <option value="" disabled>Security Question 1</option>
                                        <option :class="reset.security_question2 == q.ID ? 'd-none' : ''" v-for="q in security_questions" :key="q.ID" :value="q.ID">{{q.question}}</option>
                                    </select>

                                </div>
                            </div>
                            
                            <div class="col-lg-6">
                                <div class="form-group">
                                    <b>Your Answer to Question 1</b>
                                    <input type="text" class="form-control" placeholder="Your Answer to  Question 1" v-model="reset.security_answer1" required>
                                </div>
                            </div>

                            <div class="col-lg-6">
                                <div class="form-group">
                                    <b>Security Question 2</b>

                                    <select type="text" class="form-control select" v-model="reset.security_question2" disabled>
                                        <!-- <option  value="" disabled>Security Question 2</option> -->
                                        <option :selected="security_data.security_question2 == q.ID" :class="reset.security_question1 == q.ID ? 'd-none' : ''" v-for="q in security_questions" :key="q.ID" :value="q.ID">{{q.question}}</option>
                                    </select>
                                </div>
                            </div>

                            <div class="col-lg-6">
                                <div class="form-group">
                                    <b>Your Answer to Question 2</b>
                                    <input type="text" class="form-control" placeholder="Your Answer to Question 2" v-model="reset.security_answer2" required>
                                </div>
                            </div>


                            <div class="col-lg-6 offset-lg-3">
                                <div :class="msg_status" v-html="msg_content"></div>

                                <button type="submit" class="btn form-control login-btn" :disabled='isDisabled'>{{processBtn}}</button>
                            </div>
                        </div>

                    </form>
                </div>
            </div>
        </div>   

        <!-- change pin -->
        <div class="col-lg-9" v-if="page==3">
            <div class="card shadow-sm">
                <div class="card-header">
                    <b>Change Pin</b>
                </div>
                <div class="card-body">
                    <form  class="login-form" autocomplete="off" method="post"  v-on:submit.prevent="handlePin">
                        <div class="row" v-if="otp_screen==0">
                            
                            <div class="col-lg-6">
                                <div class="form-group">
                                    <b>Old Pin:</b>
                                    <input type="password" class="form-control" v-model="pinreset.oldpin" placeholder="Old Pin" oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1');" minlength="4" maxlength="4" required />
                                </div>
                            </div>

                            
                            <div class="col-lg-6">
                                <div class="form-group">
                                    <b>New Pin:</b>
                                    <input type="password" class="form-control" placeholder="Security PIN" oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1');" minlength="4" maxlength="4" v-model="pinreset.pin1" required>
                                </div>
                            </div>

                            
                            <div class="col-lg-6">
                                <div class="form-group">
                                    <b>Confirm New Pin:</b>
                                <input type="password" class="form-control" placeholder="Confirm Security PIN" oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1');" minlength="4" maxlength="4" v-model="pinreset.pin2" required>
                                </div>
                            </div>

                            <div class="col-lg-6 offset-lg-3">
                                <div :class="msg_status1" v-html="msg_content1"></div>
                                <button type="button" class="btn form-control login-btn"  @click="requestOTP();">Proceed</button>
                            </div>
                        </div>

                        <div class="row" v-if="otp_screen==1">
                            <div class="col-lg-12">
                                <button @click="otp_screen=0" class="btn btn-danger font-12"><i class="fa fa-arrow-left"></i> &nbsp;&nbsp;&nbsp; Back</button>
                            </div>

                            <div class="col-lg-12 text-center mt-5">
                                <p>Please enter the OTP sent to your Phone and email address to change your pin.</p>
                            </div> 
                            <div class="col-lg-6  offset-lg-3 ">                             
                                <div class="form-group">
                                    <b>Enter OTP</b>
                                    <input type="text" class="form-control" placeholder="OTP" v-model="pinreset.otp" required>
                                </div>
                            </div>

                            
                            <div class="col-lg-6 offset-lg-3">
                                <div :class="msg_status1" v-html="msg_content1"></div>
                                <button type="submit" class="btn form-control login-btn" :disabled='isDisabled1'>{{processBtn1}}</button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>  

        <div class="col-lg-3">
            <div class="card shadow-sm" style="cursor:pointer;">                             
                <div class="card-header mb-1">
                    <b>Profile Links</b>
                </div>

                <p v-if="$route.name != 'ProfileView'" class="m-0" :class="page==1 ? 'bg-scope text-white bolder':''" style="padding:10px 10px 10px 20px; border-bottom:1px solid #f2f2f2" @click="page = 1"> View Profile </p>
                <p class="m-0" :class="page==2 ? 'bg-scope text-white bolder':''" style="padding:10px 10px 10px 20px; border-bottom:1px solid #f2f2f2" @click="page = 2"> Change Password </p>
                <p class="m-0" :class="page==3 ? 'bg-scope text-white bolder':''" style="padding:10px 10px 10px 20px; border-bottom:1px solid #f2f2f2" @click="page = 3"> Change Pin </p>

            </div>
        </div>
    </div>

</template>




<script>

import axios from 'axios';


export default {
    name: "ProfileComponent",
    components: {
    },
    
    data() {
        return{
            page_active:"profile",
            isDisabled: false,
            isDisabled1: false,

            processBtn: 'Update Password',
            processBtn1: 'Update Pin',

            msg_status: '',
            msg_content: '',

            msg_status1: '',
            msg_content1: '',

            vendor_no:'',
            
            pin_msg_content: '',
            reset:{
                oldpassword:'',
                security_question1:'',
                security_question2:'',
                security_answer1:'',
                security_answer2:'',
            },         
            pinreset:{},   
            pass_policy:"",
            pass_stat:"",
            security_questions:[
                {
                    "ID":1,
                    "question":'What is the name of your favorite pet?'
                },
                {
                    "ID":2,
                    "question":'In what city were you born?'
                },
                {
                    "ID":3,
                    "question":'What is the name of your first school?'
                },
                {
                    "ID":4,
                    "question":'What is the name of your favorite Actor?'
                },
                {
                    "ID":5,
                    "question":"What is your mother's maiden name?",
                },
                {
                    "ID":6,
                    "question":'What is the name of your favorite Cousin?'
                },
                {
                    "ID":7,
                    "question":'What was the make of your first car?'
                },
                {
                    "ID":8,
                    "question":'What is your favorite color?'
                },
                {
                    "ID":9,
                    "question":'What City was your high school located?'
                }
            ],
            security_data:{
                security_question1:0,
                security_question2:0
            },            
            addresses:[],
            page:1,
            otp_screen:0,
            password_screen:0,
            freightedid:0,
            subacct_name:'',
            subacct_id:'',
            subacct_email:'',
            subacct_telephone:'',
        }
    },

    watch: {
        $route: {
            immediate: true,
            handler() {
                document.title = "FMN | Edit Profile";
            },
        },
    },

    mounted () {        
        this.vendor_no  = this.$store.getters.vendor_no;
        let subprofiles =  this.$store.getters.subaccounts;
        let sub_acct  = this.$store.getters.sub_acct;
        let subprofile_info = subprofiles.filter( el => el.customeraccount == sub_acct );
        this.addresses = subprofile_info[0].addresses;
        this.subacct_telephone = subprofile_info[0].telephone;
        this.subacct_id = subprofile_info[0].customeraccount;
        this.subacct_name = subprofile_info[0].customername;
        this.subacct_email = subprofile_info[0].emailaddress;

        this.fetchAccountQuestions(); 
        
         if(this.$route.name == 'ProfileView'){
             this.page = 2;
         }
    },

    methods: {
        async handlePassword () {
            try {
                this.msg_status     = '';
                this.msg_content    = '';

                if(this.reset.password1 == this.reset.password2 && this.pass_stat == 'ok'){
                    this.isDisabled = true,
                    this.processBtn = 'Processing...';

                    this.reset.vendor_id = this.vendor_no;

                    await axios({
                        method: "post",
                        url: this.$hostname + "/update-password",
                        data: this.reset,
                        headers: { 
                            "Content-Type": "application/json",
                            "Authorization" : `Bearer ${this.$store.getters.headerstoken}`                        
                        },
                    })

                    .then(res => {
                        this.response = res.data
                        if(this.response.status == true){

                            this.msg_status     = 'alert alert-success mt-4';
                            this.msg_content    = this.response.message;
                                            
                            this.isDisabled     = false;
                            this.processBtn     = 'Update Password';

                            this.$swal.fire(
                                'Success Alert',
                                "Your password update was successful, you will redirected to login page.",
                                'success'
                            )
                            // alert("Your password update was successful, you will redirected to login page.");
                            
                            this.$router.push('/');
                        }
                        else{
                            this.msg_status     = 'alert alert-danger mt-4';
                            this.msg_content    = this.response.message;                       
                            this.isDisabled     = false;
                            this.processBtn     = 'Update Password';
                        }                        
                    })

                    .catch(error => {
                        console.log(error)
                    })  

                    .finally(e => {                                       
                        this.isDisabled = false;
                        this.processBtn = 'Update Password';
                        console.log(e);
                    })
                }
                else{
                    this.msg_status     = 'alert alert-danger mt-4';
                    this.msg_content    = "New Password & Confirm Password did not match or meet requirement.";
                } 
            }
            catch (err) {
                this.msg_status     = 'alert alert-danger mt-4';
                this.msg_content    = err;
            }            
        },

        async checkOldPassword () {
            try {
                this.msg_status     = '';
                this.msg_content    = '';

                if(this.reset.password1 == this.reset.password2 && this.pass_stat == 'ok'){
                    var loading_html =
                        '<div style="height:150px;width:150px;margin: 0 auto;"><img style="width: 100%;" src="https://www.c-sgroup.com/images/loading-icon-red.gif" /></div>';
                        this.$swal.fire({
                        title: "",
                        html: loading_html,
                        showConfirmButton: false,
                        showCancelButton: false
                    });
                    

                    this.reset.vendor_id = this.vendor_no;

                    await axios({
                        method: "post",
                        url: this.$hostname + "/check-old-password",
                        data: this.reset,
                        headers: { 
                            "Content-Type": "application/json",
                            "Authorization" : `Bearer ${this.$store.getters.headerstoken}`  
                        },
                    })

                    .then(res => {
                        this.response = res.data
                        if(this.response.status == true){
                            this.password_screen=1;

                            this.$swal.fire(
                                'Success Alert',
                                this.response.message,
                                'success'
                            )

                        }
                        else{
                            this.$swal.fire(
                                'Alert',
                                res.data.message,
                                'error'
                            )
                        }                        
                    })

                    .catch(error => {
                        console.log(error)
                    })
                }
                else{
                    this.msg_status     = 'alert alert-danger mt-4';
                    this.msg_content    = "New Password & Confirm Password did not match or meet requirement.";
                } 
            }
            catch (err) {
                this.msg_status     = 'alert alert-danger mt-4';
                this.msg_content    = err;
            }            
        },

        async handlePin () {
            try {
                this.msg_status1     = '';
                this.msg_content1    = '';

                if(this.pinreset.pin1 == this.pinreset.pin2 && this.pinreset.pin1 != undefined){
                    this.isDisabled1 = true,
                    this.processBtn1 = 'Processing...';

                    this.pinreset.vendor_id = this.vendor_no;

                    await axios({
                        method: "post",
                        url: this.$hostname + "/update-pin",
                        data: this.pinreset,
                        headers: { 
                            "Content-Type": "application/json",
                            "Authorization" : `Bearer ${this.$store.getters.headerstoken}`  
                        },
                    })

                    .then(res => {
                        this.response = res.data
                        if(this.response.status == true){

                            this.msg_status1     = 'alert alert-success mt-4';
                            this.msg_content1    = this.response.message;
                                            
                            this.isDisabled1     = false;
                            this.processBtn1     = 'Update Pin';

                            this.$swal.fire(
                                'Success Alert',
                                this.response.message,
                                'success'
                            )
                            this.pinreset = {};

                            // this.$router.push('/');

                        }
                        else{
                            this.msg_status1     = 'alert alert-danger mt-4';
                            this.msg_content1    = this.response.message;                       
                            this.isDisabled1     = false;
                            this.processBtn1     = 'Update Pin';
                        }                        
                    })

                    .catch(error => {
                        console.log(error)
                    })  

                    .finally(e => {                                       
                        this.isDisabled1 = false;
                        this.processBtn1 = 'Update Pin';
                        console.log(e);
                    })
                }
                else{
                    this.msg_status1     = 'alert alert-danger mt-4';
                    this.msg_content1    = "New Pin & Confirm Pin did not match.";
                } 
            }
            catch (err) {
                this.msg_status1     = 'alert alert-danger mt-4';
                this.msg_content1    = err;
            }            
        },
        
        CheckPassword(x) {
            if(x.match(/^(?=.*[0-9])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{8,}$/)){ 
                this.pass_policy    = "Password strength: Strong";
                this.pass_stat      = "ok"
            }
            else {             
                this.pass_policy    = "Minimum of 8 digits with 1 numeric and 1 special character.";
                this.pass_stat      = ""
            }
        },

        requestOTP () {
            try {
                
                this.msg_status1     = '';
                this.msg_content1    = '';

                if(this.pinreset.pin1 == this.pinreset.pin2 && this.pinreset.pin1 != undefined && this.pinreset.oldpin != undefined){

                    var loading_html =
                        '<div style="height:150px;width:150px;margin: 0 auto;"><img style="width: 100%;" src="https://www.c-sgroup.com/images/loading-icon-red.gif" /></div>';
                        this.$swal.fire({
                        title: "",
                        html: loading_html,
                        showConfirmButton: false,
                        showCancelButton: false
                    });
                    
                    
                    this.pinreset.vendor_id = this.vendor_no;
                    axios({
                        method: "post",
                        url: this.$hostname + "/request-pin-otp",
                        data: this.pinreset,
                        headers: { "Content-Type": "application/json" ,
                            "Authorization" : `Bearer ${this.$store.getters.headerstoken}` },
                    })

                    .then(res => {
                        if(res.data.status == true){
                            this.otp_screen=1;
                            this.response = res.data  
                            

                            this.$swal.fire(
                                'Success Alert',
                                this.response.message,
                                'success'
                            )
                        }
                        else{
                            this.$swal.fire(
                                'Alert',
                                res.data.message,
                                'error'
                            )
                        }

                    })

                    .catch(error => {
                        console.log(error);
                        this.$swal.fire({
                            icon: 'error',
                            title: 'Oops...',
                            text: 'Something went wrong!'
                        })
                    })  

                    .finally(e => {
                        console.log(e);
                    })
                }                
                else{
                    this.msg_status1     = 'alert alert-danger mt-4';
                    this.msg_content1    = "New Pin & Confirm Pin did not match.";
                }
            }
            catch (err) {
                this.msg_status     = 'alert alert-danger mt-4';
                this.msg_content    = err;
            }            
        },

        fetchAccountQuestions (){
           try {
                 
                axios({
                    method: "post",
                    url: this.$hostname + "/fetch-account-questions",
                    data: {"vendor_no":this.vendor_no},
                    headers: { "Content-Type": "application/json" ,
                            "Authorization" : `Bearer ${this.$store.getters.headerstoken}` },
                })

                .then(res => {
                    this.security_data = res.data[0];
                    this.reset.security_question1 = this.security_data.security_question1;
                    this.reset.security_question2 = this.security_data.security_question2;

                    // alert(this.response.message);                       
                })

                .catch(error => {
                    console.log(error)
                })  

                .finally(e => {
                    console.log(e);
                })
            
            }
            catch (err) {
                this.msg_status     = 'alert alert-danger mt-4';
                this.msg_content    = err;
            } 
        }
    },

    created () {
    },
};
</script>

<style scoped>
.login-form input.form-control, .select {
    margin: 0 0 20px 0px;
}

</style>

