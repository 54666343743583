import Vue from 'vue'
import Vuex from "vuex";
import createPersistedState from "vuex-persistedstate";
Vue.use(Vuex);
Vue.config.productionTip = false;



const store = new Vuex.Store({
  state: {
    cart: [],
    cartdata: [],
    phone: '',
    email:'',
    vendor_no: '',
    sub_acct:'',
    sub_acct_bal:'',
    sub_acct_name:'',
    deliveryMode:'',
    collectionarea:'',
    collectionCategory:'',
    deliveryaddress:'',
    selected_truck:'',
    selected_category:'',
    sideNav:false,
    subaccounts:[],
    account_statement_startdate:'',
    account_statement_enddate:'',
    username:'',
    headerstoken:'',

  },

  plugins: [createPersistedState()],

  mutations: {
    updateCart(state, data) {
      state.cart = data
    },
    updateCartdata(state, data) {
      state.cartdata = data
    },
    set_vendor_no(state, data) {
      state.vendor_no = data
    },
    set_phone(state, data) {
      state.phone = data
    },
    set_email(state, data) {
      state.email = data
    },
    set_sub_acct(state, data) {
      state.sub_acct = data
    },
    set_sub_acct_bal(state, data) {
      state.sub_acct_bal = data
    },
    set_sub_acct_name(state, data) {
      state.sub_acct_name = data
    },
    set_deliveryMode(state, data) {
      state.deliveryMode = data
    },
    set_collectionarea(state, data) {
      state.collectionarea = data
    },
    set_collectionCategory(state, data) {
      state.collectionCategory = data
    },
    set_deliveryaddress(state, data){
      state.deliveryaddress = data
    },
    set_selected_truck(state, data){
      state.selected_truck = data
    },
    set_selected_category(state, data){
      state.selected_category = data
    },
    set_sideNav(state, data){
      state.sideNav = data
    },
    set_subaccounts(state, data){
      state.subaccounts = data
    },
    set_account_statement_startdate(state, data){
      state.account_statement_startdate = data
    },
    set_account_statement_enddate(state, data){
      state.account_statement_enddate = data
    },
    set_username(state, data){
      state.username = data
    },
    set_headerstoken(state, data){
      state.headerstoken = data
    },
    


  },

  getters: {
    cart: (state) => state.cart,
    cartdata: (state) => state.cartdata,
    phone: (state) => state.phone,
    email: (state) => state.email,
    vendor_no: (state) => state.vendor_no,
    sub_acct: (state) => state.sub_acct,
    sub_acct_bal: (state) => state.sub_acct_bal,
    sub_acct_name: (state) => state.sub_acct_name,
    deliveryMode: (state) => state.deliveryMode,
    collectionarea: (state) => state.collectionarea,
    collectionCategory: (state) => state.collectionCategory,
    deliveryaddress: (state) => state.deliveryaddress,
    selected_truck: (state) => state.selected_truck,
    selected_category: (state) => state.selected_category,
    sideNav: (state) => state.sideNav,
    subaccounts: (state) => state.subaccounts,
    account_statement_startdate: (state) => state.account_statement_startdate,
    account_statement_enddate: (state) => state.account_statement_enddate,
    username: (state) => state.username,
    headerstoken: (state) => state.headerstoken,

  }
});

export default store;
