<template>
    <div class="row logo-container">
        <div class="col">
            <div class="d-flex justify-content-center logo-section">
                <img src="@/assets/images/fmn-logo.png" />
                <!-- <img src="@/assets/images/logo-2.svg" /> -->
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: "LogoTop",
        props: { msg: String, },
    };
</script>