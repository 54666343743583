<template>
    <div class="container-fluid">
        <div class="row">
            <DashboardNav/>

            <div class="main-section">
                <TimeGreetings />

                <div class="content-body" style="top: 0px;">
                    
                    <div class="row breadcrumb-area">
                        <div class="col-lg-6">
                            <h6><b>Account Statement</b> - {{account_id}}</h6>
                        </div>

                        <div class="col-lg-6">
                            <nav aria-label="breadcrumb">
                                <ol class="breadcrumb">
                                    <li class="breadcrumb-item"><a href="/home">Dashboard</a></li>
                                    <li class="breadcrumb-item active" aria-current="page">Accounts Statement</li>
                                </ol>
                            </nav>                   
                        </div>
                    </div>

                    <!-- table data  -->                
                    <AccountComponent />

                    <Copyright />
                </div>
            </div>
        </div>
    </div>
</template>


<script>
import DashboardNav from '@/components/DashboardNav.vue';
import Copyright from '@/components/Copyright.vue';
import AccountComponent from '@/components/AccountComponent.vue';
import TimeGreetings from '@/components/TimeGreetings.vue';


export default {
    name: "AccountPreview",
    components: {
        DashboardNav, Copyright, AccountComponent, TimeGreetings
    },
    
    data() {
        return{            
            account : {
                customername: '',
                telephone: "",
            },
            account_id:'',
            subprofiles:[{"customername":""}],
        }
    },

    watch: {
        $route: {
            immediate: true,
            handler() {
                document.title = "FMN | Account Statement";
            },
        },
    },

    mounted () {                
        this.subprofiles =  this.$store.getters.subaccounts;

        this.account_id = this.$route.params.id;
        this.account = this.$store.getters.subaccounts.find((c) => c.customeraccount == this.account_id );
    },

    methods: {

    },

    created () {
        
    },

    computed: {
    },
};
</script>

<style scoped>
label{position: absolute;top: -15px;font-weight: 500;font-size: 12px;}
.login-form .form-check-input {border: 2px solid #14142B;border-radius: 8px !important;}
ul { padding-left: 10px;margin-bottom: 0;}
.side-nav-bg-cover {width: 30px;left: 0px;}
.side-nav {width: 80px;}
.side-link.active > a {padding: 0 5px 0 25px;}
.side-nav ul li a {color: #fff;display: block;padding: 0 5px 0px 25px;}
.main-section {width: calc(100% - 80px);margin-left: 80px;background-position: 45% 70%;}
.side-link-after {padding: 15px 0px 20px !important;}
.subprofile .card-body {box-shadow: none;}
.dashboard-icon1{font-size: 24px;background: #000;padding: 12px 14px;border-radius: 25px;color: #ffd300;box-shadow: 1px 2px 6px 6px #f2f2f2;top: -5px;}
.btn{background: #000;color: #fff;}
.form-check-label {font-weight: 600;font-size: 12px;}
.h1 {font-size: 26px;font-weight: 600;}
.side-link.active, .container-fluid {background: #f6f6f6 !important;}
.card{background: #fff;}
</style>