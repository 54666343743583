import Vue from 'vue'
import App from './App.vue'
import router from './router'
import axios from 'axios'
import VueAxios from 'vue-axios'
import store from './store'
import "../src/assets/css/style.css"

import vSelect from "vue-select";



import VueSweetalert2 from 'vue-sweetalert2';
import 'sweetalert2/dist/sweetalert2.min.css';
import "vue-select/dist/vue-select.css";


Vue.use(VueSweetalert2);

import "jquery";

const { $, jQuery } = require('jquery');
global.$ = $;
global.jQuery = jQuery;

import Raphael from 'raphael/raphael'
global.Raphael = Raphael


Vue.prototype.$hostname = 'https://fmnapi.fmnplc.website'
// Vue.prototype.$hostname = 'http://localhost:8000'


// preloader;
Vue.prototype.$preloader = '<div style="height:150px;width:150px;margin: 0 auto;"><img style="width: 100px;" src="https://www.c-sgroup.com/images/loading-icon-red.gif" /><br> <strong> Please Wait... </strong> </div>';

//number to thousands
// with (2) decimals
Vue.mixin({
  methods: {
    thDFormat: function (x) {
      if(x)
       return x.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    },
  },
})


Vue.config.productionTip = false;


import Bars from 'vuebars'
Vue.use(Bars)
Vue.component("v-select", vSelect)

 
Vue.use(VueAxios, axios)

// mount app
new Vue({
  store,
  router,
  render: h => h(App)
}).$mount('#app')