<template>
    <div class="row mt-3">                    
        <div class="col">
            <h1 class="p-1 m-3 h1">
                <i class="far fa-user dashboard-icon1"></i>
                {{ new Date().getHours() > 17 ? 'Good Evening,' : new Date().getHours() > 12 ? 'Good Afternoon,' : 'Good Morning,' }}
                <!-- <span style="text-transform: capitalize;">{{subprofiles[0].customername.replace(/ .*/,'').toLowerCase()}}!</span> -->
                <span style="text-transform: capitalize;">{{username}}!</span>
            </h1>
        </div>
    </div>
</template>

<script>
export default {
    name: "TimeGreetings",
    data(){
        return{              
            subprofiles:[{"customername":""}],
            username:''

        }
    },
    mounted () {
        this.subprofiles = this.$store.getters.subaccounts;
        this.username = this.$store.getters.username;
    },
};
</script>

<style scoped>
.dashboard-icon1{font-size: 24px; background: #000;padding: 12px 14px;border-radius: 25px;color: #ffd300;box-shadow: 1px 2px 6px 6px #f2f2f2;top: -5px;}
.h1 {font-size: 26px;font-weight: 600;}
</style>

