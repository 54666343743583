<template>
    <div class="container">

        <LogoTop />

        <div class="row">
            <div class="col-lg-6 d-none d-sm-none d-lg-block" style="top: -70px; z-index: -1;">          
                <img src="@/assets/images/padlock-image.svg">
            </div>

            <div class="col-lg-5 offset-lg-1 col-sm-8 offset-sm-2 col-12 form-cover">
                <h1 class="welcome-h1">Reset your<br>Password</h1>

                <form class="login-form reset-pasword" autocomplete="off" method="post" v-on:submit.prevent="handlePassword">
                    <div class="form-group">
                        <input :type="passwordFieldType" class="form-control" v-model="reset.password1" required placeholder="New Password" minlength="8" @keyup="CheckPassword(reset.password1)">
                        <i @click="passwordFieldType == 'password' ? passwordFieldType = 'text' : passwordFieldType = 'password'" :class="passwordFieldType == 'password' ? 'far fa-eye' : 'fa fa-eye-slash'"></i>

                        
                        <small class="small-hint" :class="pass_stat == 'ok' ? 'text-success bolder' : 'text-danger'">{{pass_policy}}</small>
                    </div>

                    <div class="form-group">
                        <input :type="passwordFieldType2" class="form-control" v-model="reset.password2" required placeholder="Confirm Password" minlength="8">
                        <i @click="passwordFieldType2 == 'password' ? passwordFieldType2 = 'text' : passwordFieldType2 = 'password'" :class="passwordFieldType2 == 'password' ? 'far fa-eye' : 'fa fa-eye-slash'"></i>
                    </div>

                    <div :class="msg_status" v-html="msg_content"></div>

                    <button type="submit" class="btn form-control login-btn" :disabled='isDisabled'>{{processBtn}}</button>

                    <div class="text-center mt-3">
                        <p><a href="/">Back to Sign In</a></p>
                    </div>
                </form>

            </div>
        </div>        

        <Copyright />
    </div>
</template>




<script>
import LogoTop from '@/components/LogoTop.vue';
import Copyright from '@/components/Copyright.vue';
import axios from 'axios';

export default {
    name: "ForgotPassword",
    components: {
        LogoTop, Copyright
    },
    
    data() {
        return{
            passwordFieldType: 'password',
            passwordFieldType2: 'password',
            isDisabled: false,
            processBtn: 'RESET PASSWORD',
            msg_status: '',
            msg_content: '',
            reset:{},            
            pass_policy:"",
            pass_stat:"",
        }
    },

    watch: {
        $route: {
            immediate: true,
            handler() {
                document.title = "Reset Password - FMN";
            },
        },
    },

    methods: {
        async handlePassword () {
            let urlSearchParams = new URLSearchParams(window.location.search);
            let params = Object.fromEntries(urlSearchParams.entries());


            try {
                this.msg_status     = '';
                this.msg_content    = '';

                if(this.reset.password1 == this.reset.password2 && this.pass_stat == 'ok'){
                    this.isDisabled = true,
                    this.processBtn = 'Processing...';

                    this.reset.resetcode = params.q;
                    this.reset.username  = params.u;

                    await axios({
                        method: "post",
                        url: this.$hostname + "/change-password",
                        data: this.reset,
                        headers: { "Content-Type": "application/json" },
                    })

                    .then(res => {
                        this.response = res.data
                        if(this.response.status == true){

                            this.msg_status     = 'alert alert-success mt-4';
                            this.msg_content    = this.response.message;
                                            
                            this.isDisabled     = false;
                            this.processBtn     = 'RESET PASSWORD';

                            // this.$router.push('/');

                        }
                        else{
                            this.msg_status     = 'alert alert-danger mt-4';
                            this.msg_content    = this.response.message;                       
                            this.isDisabled     = false;
                            this.processBtn     = 'RESET PASSWORD';
                        }                        
                    })

                    .catch(error => {
                        console.log(error)
                    })  

                    .finally(e => {                                       
                        this.isDisabled = false;
                        this.processBtn = 'RESET PASSWORD';
                        console.log(e);
                    })
                }
                else{
                    this.msg_status     = 'alert alert-danger mt-4';
                    this.msg_content    = "New Password & Confirm Password did not match or meet requirement.";
                } 
            }
            catch (err) {
                this.msg_status     = 'alert alert-danger mt-4';
                this.msg_content    = err;
            }            
        },
        
        CheckPassword(x) {
            if(x.match(/^(?=.*[0-9])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{8,}$/)){ 
                this.pass_policy    = "Password strength: Strong";
                this.pass_stat      = "ok"
            }
            else {             
                this.pass_policy    = "Minimum of 8 digits with 1 numeric and 1 special character.";
                this.pass_stat      = ""
            }
        },
    }
};
</script>

<style scoped>
.login-form .form-group i {
    top: 17px;
    font-size: 25px;
}
.small-hint {
    left: 10px;
}
</style>

