<template>
    <div class="dashboard">

        <SideNav v-bind:page_active="page_active" />

        <div class="main-section">

            <TopNav />

            <div class="content-body">
                <!-- page breadcrumb -->
                <div class="row breadcrumb-area">
                    <div class="col-lg-6">
                        <h6><b>Sales Order</b></h6>
                    </div>

                    <div class="col-lg-6">
                        <nav aria-label="breadcrumb">
                            <ol class="breadcrumb">
                                <li class="breadcrumb-item"><a href="/dashboard">Home</a></li>
                                <li class="breadcrumb-item active" aria-current="page">Sales Order</li>
                            </ol>
                        </nav>                   
                    </div>
                </div>


                <!-- order data -->
                <div class="row filter-wrap mb-3">
                    <div class="mt-5 mb-3 col-lg-12">
                        <ul class="nav nav-tabs">
                            <li class="nav-item">
                                <a class="nav-link" :class="order_tab_active == 'PENDING' ? 'active' : ''" @click="order_tab_active = 'PENDING'" aria-current="page" href="#">Pending Orders</a>
                            </li>
                            <li class="nav-item">
                                <a class="nav-link" :class="order_tab_active == 'MODIFIED' ? 'active' : ''" @click="order_tab_active = 'MODIFIED'" aria-current="page" href="#">Modified Orders</a>
                            </li>
                            <li class="nav-item">
                                <a class="nav-link" :class="order_tab_active == 'Backorder' ? 'active' : ''" @click="order_tab_active = 'Backorder'" aria-current="page" href="#">Open Orders</a>
                            </li>
                            <li class="nav-item">
                                <a class="nav-link" :class="order_tab_active == 'Invoiced' ? 'active' : ''" @click="order_tab_active = 'Invoiced'" aria-current="page" href="#">Invoiced Orders</a>
                            </li>
                            <li class="nav-item">
                                <a class="nav-link" :class="order_tab_active == 'Canceled' ? 'active' : ''"  @click="order_tab_active = 'Canceled'" aria-current="page" href="#">Cancelled Orders</a>
                            </li>
                        </ul>
                    </div>



                    <!-- list from ORM pending -->
                    <div class="col-lg-12">
                        <div class="" style="overflow-x:scroll; width:100%" v-if="order_tab_active == 'PENDING'">
                            <table class="table table-striped table-hover" v-if="order_tab_active != 'MODIFIED'">
                                <thead>
                                    <tr style="border-bottom: 2px solid #000;">
                                        <th>SN</th>
                                        <th>Order Number</th>
                                        <th>Total</th>
                                        <th>Sub Total</th>
                                        <th>Freighted Charge</th>
                                        <th>Total Items</th>
                                        <th>Collection Method</th>
                                        <th>Loading Location</th>
                                        <th>Delivery Address</th>
                                        <th>Date</th>
                                        <!-- <th>Updated Date</th> -->
                                        <th>Status</th>

                                        <th scope="col" style="width: 20px;">View</th>
                                    </tr>
                                </thead>
                                <tbody v-if="isOpen">
                                    <tr v-for="(x,i) in oms_pending_list" :key="i">
                                        <td></td>
                                        <td>{{x.order_no}}</td>
                                        <td>&#x20A6;{{ thDFormat(Number(x.amount) + Number(x.freighted_charge)) }}</td>
                                        <td>&#x20A6;{{ thDFormat(Number(x.amount)) }}</td>
                                        <td>&#x20A6;{{ thDFormat(Number(x.freighted_charge)) }}</td>
                                        <td>{{x.order_items_count}} Item(s)</td>

                                        <td>{{x.colection_methodID}}</td>
                                        <td>{{x.loading_locationID}}</td>
                                        <td>{{x.ship_address}}</td>
                                        <td>{{x.date_created}}</td>
                                        <!-- <td>{{x.updated_at}}</td> -->
                                        <td>{{x.order_status}}</td>
                                        <td>
                                            <u><a href="#!" @click="view_index3 = i" class="d-block color-scope"  data-bs-toggle="modal" data-bs-target="#filterModal4">View</a></u>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>

                        <!-- <div v-if="nodata == 1" class="text-center">
                            <img src="https://emall.qa/images/empty_item.svg" style="width:20%" />
                            <br>
                            
                            <h5><b>NO RECORD FOUND</b></h5>

                        </div> -->
                    </div>                   

                    <!-- list from ORM modified -->
                    <div class="col-lg-12">
                        <div class="" style="overflow-x:scroll; width:100%" v-if="order_tab_active == 'MODIFIED'">
                            <table class="table table-striped table-hover" v-if="order_tab_active != 'PENDING'">
                                <thead>
                                    <tr style="border-bottom: 2px solid #000;">
                                        <th>SN</th>
                                        <th>Order Number</th>
                                        <th>Total</th>
                                        <th>Sub Total</th>
                                        <th>Freighted Charge</th>
                                        <th>Total Items</th>
                                        <th>Collection Method</th>
                                        <th>Loading Location</th>
                                        <th>Delivery Address</th>
                                        <th>Date</th>
                                        <!-- <th>Updated Date</th> -->
                                        <th>Status</th>

                                        <th scope="col" style="width: 20px;">View</th>
                                    </tr>
                                </thead>
                                <tbody v-if="isOpen1">
                                    <tr v-for="(x,i) in oms_order_list" :key="i">
                                        <td></td>
                                        <td>{{x.order_no}}</td>
                                        <td>&#x20A6;{{ thDFormat(Number(x.amount) + Number(x.freighted_charge)) }}</td>
                                        <td>&#x20A6;{{ thDFormat(Number(x.amount)) }}</td>
                                        <td>&#x20A6;{{ thDFormat(Number(x.freighted_charge)) }}</td>
                                        <td>{{x.order_items_count}} Item(s)</td>

                                        <td>{{x.colection_methodID}}</td>
                                        <td>{{x.loading_locationID}}</td>
                                        <td>{{x.ship_address}}</td>
                                        <td>{{x.date_created}}</td>
                                        <!-- <td>{{x.updated_at}}</td> -->
                                        <td>{{x.order_status}}</td>
                                        <td>
                                            <u><a href="#!" @click="view_index = i" class="d-block color-scope"  data-bs-toggle="modal" data-bs-target="#filterModal3">View</a></u>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>

                        <!-- <div v-if="nodata1 == 1" class="text-center">
                            <img src="https://emall.qa/images/empty_item.svg" style="width:20%" />
                            <br>
                            
                            <h5><b>NO RECORD FOUND</b></h5>

                        </div> -->
                    </div>


                    <!-- list from AX -->
                    <div class="col-lg-12">
                        <div class="filter-area" data-bs-toggle="modal" data-bs-target="#filterModal">
                            <span>
                                <svg width="33" height="33" style="margin-right:30px; top: 0px;" viewBox="0 0 38 38" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M15.8333 28.5H22.1667V25.3333H15.8333V28.5ZM4.75 9.5V12.6667H33.25V9.5H4.75ZM9.5 20.5833H28.5V17.4167H9.5V20.5833Z" fill="#006E40"/></svg>
                            </span>
                            <small>  Filter  </small>
                        </div>


                        <div class="" style="overflow-x:scroll; width:100%" v-if="order_tab_active != 'MODIFIED'">
                            <table class="table table-striped table-hover" v-if="order_tab_active != 'PENDING'">
                                <thead>
                                    <tr style="border-bottom: 2px solid #000;">
                                        <th>SN</th>
                                        <th>order ID</th>
                                        <th>Description</th>
                                        <th scope="col" class="pointer" :class="[order_set=='date' ? 'active':'', theorder==1?'up':'down']">
                                            Date  
                                            <i class="fa fa-caret-up" style="top:-3px" @click="sortBy('date')"></i> 
                                            <i class="fa fa-caret-down" @click="reverseSort('date')"></i>
                                        </th>
                                        <th scope="col" class="pointer" :class="[order_set=='deliveryterms' ? 'active':'', theorder==1?'up':'down']">
                                            Delivery Terms  
                                            <i class="fa fa-caret-up" style="top:-3px" @click="sortBy('deliveryterms')"></i> 
                                            <i class="fa fa-caret-down" style="right: 10px;top: 4px;" @click="reverseSort('deliveryterms')"></i>
                                        </th>
                                        <th scope="col" class="pointer" :class="[order_set=='deliveryaddress' ? 'active':'', theorder==1?'up':'down']">
                                            Delivery Address 
                                            <i class="fa fa-caret-up" style="top:-3px" @click="sortBy('deliveryaddress')"></i> 
                                            <i class="fa fa-caret-down" style="right: 10px;top: 4px;" @click="reverseSort('deliveryaddress')"></i>
                                        </th>
                                        <!-- <th scope="col" class="pointer" :class="[order_set=='warehouse' ? 'active':'', theorder==1?'up':'down']">
                                            Warehouse 
                                            <i class="fa fa-caret-up" style="top:-3px" @click="sortBy('warehouse')"></i> 
                                            <i class="fa fa-caret-down" style="right: 10px;top: 4px;" @click="reverseSort('warehouse')"></i>
                                        </th>
                                        <th scope="col">Status</th> -->

                                        <!-- <th scope="col" style="width: 65%;">Sales Order Line</th> -->
                                        <th scope="col" style="width: 20px;">View</th>
                                        <th scope="col" style="width: 20px;">Track</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr v-for="(x,i) in order_list" :key="i" :class="x.salesorderline[0].linestatus == order_tab_active ? '' : 'd-none'">
                                        <td></td>

                                        <td>{{x.orderID}}</td>
                                        <td></td>
                                        <td>{{x.date}}</td>
                                        <td>{{x.deliveryterms == 'CFR' ? 'FMN Delivery' : 'Self Collection'}}</td>
                                        <td>{{x.deliveryaddress}}</td>
                                        <!-- <td>{{x.warehouse}}</td> -->
                                        <!-- <td>{{x.salesorderline[0].linestatus}}</td> -->
                                        <td>
                                            <u><a href="#!" @click="view_index2 = i" class="d-block color-scope"  data-bs-toggle="modal" data-bs-target="#filterModal2">View</a></u>
                                        </td>
                                        <td>
                                            <u v-if="x.deliveryterms == 'CFR'">
                                                <span v-if="x.salesorderline[0].linestatus == 'Invoiced'" target="blank" class="d-block color-scope">
                                                    <a v-if="x.tracking_link != ''" :href="x.tracking_link">Track</a>
                                                    <span v-else  @click="$swal.fire('Alert','No Tracking Available', 'error')" >Track</span>
                                                </span>
                                            </u>
                                        </td>
                                    </tr>


                                    <!-- <tr>
                                        <th scope="row">                                        
                                            <div class="form-check">
                                                <input class="form-check-input" type="checkbox" value="" id="flexCheckChecked" checked>
                                            </div>
                                        </th>
                                        <td>Pasta</td>
                                        <td>500</td>
                                        <td>5</td>
                                        <td>&#x20A6;90,000</td>
                                        <td>Freighted </td>
                                        <td>Processing</td>
                                        <td>
                                            <a href="/track-order" class="order-action text-dark order-action text-dark ">Track</a>
                                        </td>
                                    </tr>
                                    <tr>
                                        <th scope="row">                                        
                                            <div class="form-check">
                                                <input class="form-check-input" type="checkbox" value="" id="flexCheckChecked">
                                            </div>
                                        </th>
                                        <td>Flour</td>
                                        <td>1500</td>
                                        <td>11</td>
                                        <td>&#x20A6;400,000</td>
                                        <td>Self-Collection</td>
                                        <td>Dispatched</td>
                                        
                                    </tr> -->
                                </tbody>
                            </table>
                        </div>

                        <!-- <div v-if="nodata2" class="text-center">
                            <img src="https://emall.qa/images/empty_item.svg" style="width:20%" />
                            <br>
                            
                            <h5><b>NO RECORD FOUND</b></h5>

                        </div> -->
                    </div>
                </div>

                <Copyright />


                <!-- search - filter order from AX -->
                <div class="modal fade" id="filterModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                    <div class="modal-dialog">
                        <div class="modal-content shadow-sm">
                            <div class="modal-header">
                                <h5 class="modal-title color-scope bolder" id="exampleModalLabel">Filter</h5>
                                <button type="button" class="btn-close" id="closebtn" data-bs-dismiss="modal" aria-label="Close"></button>
                            </div>
                            <div class="modal-body">

                                <p class="bolder mb-3 mt-5">Select date range</p>

                                <div class="row">
                                    <div class="col-12">                                    
                                        <input type="date" v-model="startdate" class="form-control border-b" style="height: 55px;">
                                    </div>
                                    <div class="col-12 mt-3">                                               
                                        <input type="date" v-model="enddate" class="form-control border-b" style="height:55px">
                                    </div>

                                    <div class="col-12 mt-3">                                               
                                        <button type="button" @click="filterResult()" class="btn scope-bg btn-success col-12" style="height:55px">Filter</button>
                                    </div>


                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <!-- order item from AX -->
                <div class="modal fade" id="filterModal2" tabindex="-1" aria-labelledby="exampleModalLabe2" aria-hidden="true">
                    <div class="modal-dialog">
                        <div class="modal-content shadow-sm">
                            <div class="modal-header">
                                <h5 class="modal-title color-scope bolder" id="exampleModalLabel">Order Line Items</h5>
                                
                                <button type="button" class="btn-close" id="closebtn" data-bs-dismiss="modal" aria-label="Close"></button>
                            </div>
                            <div class="modal-body">
                                            
                                <table class="table table-striped table-hover">
                                    <thead>
                                            <tr>
                                                <td>S/N</td>
                                                <td>Item No</td>
                                                <td>Product Name</td>
                                                <td>Unit Price</td>
                                                <td>Quantity</td>
                                                <td>Unit</td>
                                                <td>Price</td>
                                                <td>Site</td>
                                                <td style="width:90px">Line Status</td>

                                            </tr>
                                    </thead>
                                    <tbody>
                                        <tr v-for="(p,j) in order_list[view_index2].salesorderline" :key="j">
                                            <td></td>
                                            <td> {{p.itemno}}</td>
                                            <td> {{p.productname}}</td>
                                            <td> &#x20A6;{{ thDFormat(Number(p.unitprice)) }}</td>
                                            <td> {{ thDFormat(Number(p.quantity)) }}</td>
                                            <td> {{p.unit}}</td>
                                            <td> &#x20A6;{{ thDFormat(Number(p.price)) }}</td>
                                            <td> {{p.site}}</td>
                                            <td> {{p.linestatus == 'Backorder' ? 'Open Order' : p.linestatus}}</td>
                                        </tr>
                                    </tbody>
                                </table>

                                
                            </div>
                        </div>
                    </div>
                </div>

                <!-- modified order item from OMS -->
                <div class="modal fade" id="filterModal3" tabindex="-1" aria-labelledby="exampleModalLabe3" aria-hidden="true">
                    <div class="modal-dialog">
                        <div class="modal-content shadow-sm">
                            <div class="modal-header">
                                <h5 class="modal-title color-scope bolder" id="exampleModalLabel">Order Line Items</h5>
                                
                                <button type="button" class="btn-close" id="closebtn" data-bs-dismiss="modal" aria-label="Close"></button>
                            </div>
                            <div class="modal-body">
                                            
                                <table class="table table-striped table-hover">
                                    <thead>
                                            <tr>
                                                <td>S/N</td>
                                                <td>Item No</td>
                                                <td>Product Name</td>
                                                <td>Suggested Product</td>
                                                <td>Unit Price</td>
                                                <td>Suggested Unit Price</td>
                                                <td>Ordered Quantity</td>
                                                <td>Suggested Quantity</td>
                                                <td>Unit</td>
                                                <td>Reason</td>
                                                <td>Modification Type</td>
                                                <td>Suggested Total</td>
                                                <!-- <td>Price</td> -->
                                                <!-- <td>Site</td> -->
                                                <!-- <td>Line Status</td> -->

                                            </tr>
                                    </thead>
                                    <tbody>
                                        
                                        <tr v-for="(p,j) in oms_order_list[view_index].order_items" :key="j" :style="p.modificationtype != '' ? 'background:yellow':''">
                                            <td></td>
                                            <td> {{p.productID}}</td>
                                            <td> {{p['products'].productname}}</td>

                                            <td> {{p['modifyproducts'].productname}}</td>
                                            <td> &#x20A6;{{ thDFormat(Number(p.price)) }}</td>
                                            <td> &#x20A6;{{ thDFormat(Number(p.suggested_price)) }}</td>

                                            <td> 
                                                {{ thDFormat(Number(p.quantity)) }} 
                                                ({{ thDFormat(p.quantity/p['products'].bagcount) }} tonne
                                                <span v-if="p.quantity/p['products'].bagcount > 1">s</span>) 
                                            </td>

                                            <td>
                                                <span v-if="p.suggested_quantity > 0">
                                                    {{ thDFormat(Number(p.suggested_quantity)) }} 
                                                    
                                                    ({{ p.suggested_quantity/p['products'].bagcount }} 
                                                    
                                                    tonne<span v-if="p.suggested_quantity/p['products'].bagcount > 1">s</span>)
                                                </span>

                                                <span v-if="p.suggested_quantity == 0">
                                                    {{ thDFormat(Number(p.quantity)) }} 
                                                    
                                                    ({{ p.quantity/p['products'].bagcount }} tonne<span v-if="p.quantity/p['products'].bagcount > 1">s</span>)
                                                </span>
                                                
                                            </td>


                                            <td> {{p.unit}}</td>
                                            <td> {{p.reason}}</td>

                                            <td> {{p.modificationtype == 'quantitychange' ? 'quantity updated' : p.modificationtype == 'productchange' ? 'Product replaced' : ''}} </td>


                                            <td> 
                                                <span v-if="p.suggested_quantity > 0">                                                
                                                    &#x20A6;{{ thDFormat(p.suggested_quantity * p.suggested_price) }}
                                                </span>

                                                <span v-if="p.suggested_quantity == 0">
                                                    &#x20A6;{{ thDFormat(p.quantity * p.price) }}
                                                </span>
                                            </td>
                                        </tr>
                                    </tbody>
                                    
                                    <thead>   

                                        <tr>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td style="width:150px">Suggested Grand Total</td>

                                            <td>
                                                &#x20A6;{{

                                                    oms_order_list[view_index].order_items.length > 0 ?
                                                    
                                                    thDFormat(oms_order_list[view_index].order_items.map(x => (x.suggested_quantity > 0 ? x.suggested_price : x.price ) * (x.suggested_quantity > 0 ? x.suggested_quantity : x.quantity)).reduce( (p, v) => Number(p) + Number(v))) : 0
                                                
                                                }}
                                            </td>
                                        </tr>

                                    </thead>
                                </table>

                                <button class="btn btn-success btn-large text-end" @click="changeModify(oms_order_list[view_index].ID,'APPROVED')" style="margin-right:10px"  data-bs-dismiss="modal" aria-label="Close">Accept</button>
                                <button class="btn btn-danger btn-large text-end" @click="changeModify(oms_order_list[view_index].ID,'REJECTED')" data-bs-dismiss="modal" aria-label="Close" style="margin-right:10px">Cancel</button>
                                <button class="btn btn-info btn-large text-end text-white" data-bs-dismiss="modal" aria-label="Close" >Close</button>
                                
                            </div>
                        </div>
                    </div>
                </div>

                <!-- pending order item from OMS -->
                <div class="modal fade" id="filterModal4" tabindex="-1" aria-labelledby="exampleModalLabe4" aria-hidden="true">
                    <div class="modal-dialog">
                        <div class="modal-content shadow-sm">
                            <div class="modal-header">
                                <h5 class="modal-title color-scope bolder" id="exampleModalLabel">Order Line Items</h5>
                                
                                <button type="button" class="btn-close" id="closebtn" data-bs-dismiss="modal" aria-label="Close"></button>
                            </div>
                            <div class="modal-body">
                                            
                                <table class="table table-striped table-hover">
                                    <thead>
                                            <tr>
                                                <td>Item No</td>
                                                <td>Product Name</td>
                                                <!-- <td>Unit</td> -->
                                                <td>Unit Price</td>
                                                <td>Quantity</td>
                                                <!-- <td>Suggested Quantity</td> -->
                                                <td>Price</td>
                                                <!-- <td>Site</td> -->
                                                <!-- <td>Line Status</td> -->

                                            </tr>
                                    </thead>
                                    <tbody>
                                        
                                        <tr v-for="(p,j) in oms_pending_list[view_index3].order_items" :key="j">
                                            <td> {{p.productID}}</td>
                                            <td> {{p['products'].productname}}</td>
                                            <!-- <td> {{p.unit}}</td> -->
                                            <td> &#x20A6;{{ thDFormat(Number(p.price)) }}</td>
                                            <td> {{ thDFormat(Number(p.quantity)) }} {{p.unit}}</td>
                                            <!-- <td> {{ thDFormat(p.suggested_quantity) }}</td> -->
                                            <td> &#x20A6;{{ thDFormat(Number(p.amount)) }}</td>
                                            <!-- <td> {{p.site}}</td> -->
                                            <!-- <td> {{p.linestatus}}</td> -->
                                        </tr>
                                    </tbody>
                                </table>

                                <!-- <button class="btn btn-success btn-large text-end" @click="changeModify(oms_order_list[view_index].ID,'APPROVED')" style="margin-right:10px"  data-bs-dismiss="modal" aria-label="Close">Approve</button>
                                <button class="btn btn-danger btn-large text-end" @click="changeModify(oms_order_list[view_index].ID,'REJECTED')" data-bs-dismiss="modal" aria-label="Close">Cancel</button>
                                 -->
                            </div>
                        </div>
                    </div>
                </div>


            </div>
        </div>
    </div>
</template>




<script>
import SideNav from '@/components/SideNav.vue';
import Copyright from '@/components/Copyright.vue';
import TopNav from '@/components/TopNav.vue';
import moment from 'moment';


import axios from 'axios';

export default {
    name: "Orders",
    components: {
        SideNav, TopNav, Copyright
    },
    
    data() {
        return{
            page_active:"orders",         
            
            order_list:[
                {
                    "salesorderline":[
                        {"linestatus":"", "unitprice":"", "price":"", "itemno":"", "productname":"","unit":"","quantity":"", "site":""}
                    ]
                }
            ],

            obj_order:{},

            subaccountid:'',
            currentBalance:'',
            creditRemaining:'',
            avaiableBalance:'',
            sub_acct:'',
            order_set:'',
            theorder:'',

            startdate:'',
            enddate:'',
            nodata:0,
            view_index:0,
            view_index2:0,
            view_index3:0,
            order_tab_active :'PENDING',
            oms_order_list:[{"order_items":[]}],
            oms_pending_list:[{"order_items":[]}],
            isOpen:false,
            isOpen1:false,
            isOpen2:false,
        }
    },

    watch: {
        $route: {
            immediate: true,
            handler() {
                document.title = "FMN | Dashboard";
            },
        },
    },

    mounted () {  
        if (this.$store.getters.vendor_no) {
            this.phone      = this.$store.getters.phone; 
            this.vendor_no  = this.$store.getters.vendor_no;    
            this.sub_acct   = this.$store.getters.sub_acct;            
        }
        
        this.obj_order.startdate = moment().subtract(2, 'months').format('YYYY/MM/DD');
        this.obj_order.enddate = moment().add(1, 'days').format('YYYY/MM/DD');

        this.getSalesOrders(); // load sales order history from AX
        this.getOMSOrders(); // load sales order history from 
        this.getPendingOrders(); // load sales order history from 
    },

    methods: {
        async getSalesOrders () {
            this.$swal.fire({title: "", html: this.$preloader, showConfirmButton: false, showCancelButton: false}); // preloader
            this.obj_order.subaccountID = this.sub_acct;

            this.obj_order.page = 2;
            this.obj_order.pagesize = 100;
            this.obj_order.linestatus = "";

            try {                                 
                await axios({
                    method: "get",
                    url: this.$hostname+"/api/getsaleordertransactions",
                    params: this.obj_order,
                    headers: { "Content-Type": "application/json",
                            "Authorization" : `Bearer ${this.$store.getters.headerstoken}` },
                })

                .then(res => {
                    if(res.data.data.length > 0){
                        this.order_list = res.data.data;
                        this.reverseSort('date');
                    }


                    if(res.data.data.length == 0){
                         this.nodata2 = true;
                    }

                    this.$swal.close();


                })

                .catch(error => {
                    console.log(error)
                })
            }
            catch (err) {
                console.log(err)
            }            
        },
    
        async getOMSOrders () {
            try {                                 
                await axios({
                    method: "get",
                    url: this.$hostname+"/api/modifysalesorder",
                    params: {"status":"MODIFIED", "sub_accountID": this.sub_acct},
                    headers: { "Content-Type": "application/json",
                            "Authorization" : `Bearer ${this.$store.getters.headerstoken}` },
                })

                .then(res => {
                    if(res.data.data.data.length > 0){
                        this.isOpen1 = true;
                        this.oms_order_list = res.data.data.data;
                    }

                })

                .catch(error => {
                    console.log(error)
                })
            }
            catch (err) {
                console.log(err)
            }            
        },
    
        async getPendingOrders () {
            try {                                 
                await axios({
                    method: "get",
                    url: this.$hostname+"/api/modifysalesorder",
                    params: {"status":"PENDING", "sub_accountID": this.sub_acct},
                    headers: { "Content-Type": "application/json",
                            "Authorization" : `Bearer ${this.$store.getters.headerstoken}` },
                })

                .then(res => {
                    if(res.data.data.data.length > 0){
                        this.isOpen = true;
                        this.oms_pending_list = res.data.data.data;
                    }

                })

                .catch(error => {
                    console.log(error)
                })
            }
            catch (err) {
                console.log(err)
            }            
        },

        async changeModify (x,y){
            
            this.$swal.fire({title: "", html: this.$preloader, showConfirmButton: false, showCancelButton: false}); // preloader

            console.log(x,y);
            try {                                 
                await axios({
                    method: "post",
                    url: this.$hostname+"/api/postmodifiedorders",
                    data: {"id":x, "status": y},
                    headers: { "Content-Type": "application/json",
                            "Authorization" : `Bearer ${this.$store.getters.headerstoken}` },
                })

                .then(res => {
                    this.oms_order_list = [{"order_items":[]}];
                    this.getOMSOrders();
                    // alert(res.data.message);

                    this.$swal.close();
                    this.$swal.fire(
                                'Success Alert',
                                res.data.message,
                                'success'
                            )
                    

                })

                .catch(error => {
                    console.log(error)
                })
            }
            catch (err) {
                console.log(err)
            } 
        },

        sortBy (x){
            this.order_set = x;
            this.theorder = 1;
            this.order_list.sort(function(a, b) {
                
                var nameA = a[x].toUpperCase(); // ignore upper and lowercase
                var nameB = b[x].toUpperCase(); // ignore upper and lowercase

                if (nameA < nameB) {
                    return -1;
                }
                if (nameA > nameB) {
                    return 1;
                }

                return 0;
            });
        },

        reverseSort (x){
            this.order_set = x;
            this.theorder = 2;
            this.order_list.sort(function(a, b) {
                
                var nameA = a[x].toUpperCase(); // ignore upper and lowercase
                var nameB = b[x].toUpperCase(); // ignore upper and lowercase

                if (nameA < nameB) {
                    return -1;
                }
                if (nameA > nameB) {
                    return 1;
                }

                return 0;
            });

            this.order_list.reverse();
        },

        filterResult (){
            if(this.startdate != "" && this.enddate !== ""){
                this.obj_order.startdate    = moment(String(this.startdate)).format('YYYY/MM/DD');
                this.obj_order.enddate      = moment(String(this.enddate)).format('YYYY/MM/DD');

                this.getSalesOrders();
                document.getElementById("closebtn").click();
            }
        }
    },

    created () {
    },

    
    computed: {

    },
};
</script>

<style scoped>
.card-text{color: #5a5555;}
.card-title{font-size: 35px;}
.card-body.bg-border {border: 1px solid #006e40b3; box-shadow: none;}
th.active.up > i.fa-caret-up{color:red}
th.active.down > i.fa-caret-down{color:red}
th i {
    font-size: 14px;
}
th i.fa-caret-down{right: 9px !important;top: 4px;}
.filter-area {
    padding: 10px;
    background: #ffffff;
    border: 3px solid #006e40;
    margin-bottom: 10px;
    position: fixed;
    z-index: 100;
    right: 40px;
    width: 57px;
    bottom: 40px;
    box-shadow: 0px 1px 20px 1px #0000007d;
}


.modal-dialog { max-width: 20%; margin: 0 0 0 80%;}
.modal {z-index: 9999;width: 100%;height: 100%;}
.modal-content {height: 100vh;border-radius: 0;border: none;border-left:1px solid #dee2e6 !important;}
.modal-title {font-size: 19px;}
.modal-header { border-bottom: 1px solid #dee2e638;}
.border-b{border:2px solid #c1c1c1}

.nav-link{font-weight: 600;color: #000 !important;padding: 10px 30px;}
.nav-link.active{ 
    border-bottom: 4px solid #006e40 !important;
    border: none;
    top: 2px;
    border-radius: 3px;
}

.nav-item{margin-right: 10px;}
/* .nav-tabs {border-bottom: 1px solid #dee2e638;} */


#filterModal2 .modal-dialog, #filterModal3 .modal-dialog, #filterModal4 .modal-dialog {
    max-width: 69.2% !important;
    margin: 0 auto !important;
}




table {
    counter-reset: rowNumber;
}

table tbody tr {
    counter-increment: rowNumber;
}

table tbody tr td:first-child::before {
    content: counter(rowNumber);
    margin-right: 0.5em;
}
</style>