<template>
    <div class="dashboard">

        <SideNav v-bind:page_active="page_active" />

        <div class="main-section">

            <TopNav />

            <div class="content-body">
                <div class="row breadcrumb-area">
                    <div class="col-lg-6">
                        <h6><a href="/wallet" title="Go back to profile"><i class="fa fa-arrow-left text-dark"></i></a> &nbsp;&nbsp;&nbsp;&nbsp;  <b>Fund Wallet</b></h6>
                    </div>

                    <div class="col-lg-6">
                        <nav aria-label="breadcrumb">
                            <ol class="breadcrumb">
                                <li class="breadcrumb-item"><a href="/dashboard">Home</a></li>
                                <li class="breadcrumb-item"><a href="/wallet">Wallet</a></li>
                                <li class="breadcrumb-item active" aria-current="page">Fund Wallet</li>
                            </ol>
                        </nav>                   
                    </div>
                </div>

                <div class="row mt-3">
                    <div class="col">
                        <div class="card">
                            <div class="card-body text-center" style="padding:200px 0">
                                <b>Payment Gatement to fund wallet</b>
                            </div>                        
                        </div>                    
                    </div>
                </div>


                <Copyright />
            </div>
        </div>
    </div>
</template>




<script>
import SideNav from '@/components/SideNav.vue';
import Copyright from '@/components/Copyright.vue';
import TopNav from '@/components/TopNav.vue';



export default {
    name: "Dashboard",
    components: {
        SideNav, TopNav, Copyright
    },
    
    data() {
        return{
            page_active:"wallet",
        }
    },

    watch: {
        $route: {
            immediate: true,
            handler() {
                document.title = "FMN | Fund Wallet";
            },
        },
    },

    mounted () {        
        this.renderChart(this.chartdata, this.options)
    },

    methods: {

    },

    created () {
    }
};
</script>

<style scoped>

</style>

