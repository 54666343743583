<template>
    <div class="products">
        <SideNav v-bind:page_active="page_active" />

        <div class="main-section">
            <TopNav @searchProduct="searchProduct($event)"/> 

            <div class="content-body">
                <div class="row breadcrumb-area">
                    <div class="col-lg-6">
                        <h6><b>Product</b></h6>
                    </div>

                    <div class="col-lg-6">
                        <nav aria-label="breadcrumb">
                            <ol class="breadcrumb">
                                <li class="breadcrumb-item"><a href="/dashboard">Home</a></li>
                                <li class="breadcrumb-item active" aria-current="page">Product</li>
                            </ol>
                        </nav>                   
                    </div>
                </div>

                <div class="mt-5 mb-4 col-lg-12">
                    <ul class="nav nav-tabs">
                        <li class="nav-item" id="all_category" style="display:none">
                            <a @click="filterProduct('all')" class="nav-link" :class="obj_product.type == 'all' ? 'active' : ''" aria-current="page" href="#">All Products</a>
                        </li>
                        <li class="nav-item" v-for="(x,i) in product_category" :key="i">
                            <a @click="filterProduct(x)" class="nav-link" :class="obj_product.type == x ? 'active' : ''" aria-current="page" href="#" style="text-transform:capitalize">{{x}}</a>
                        </li>
                    </ul>
                </div>

                <div class="row" v-if="product_list.length > 0">
                    <div class="col-lg-3 text-center mb-4 shadow-hover border product-hover" v-for="(x,i) in product_list" :key="i">
                        <div class="">
                            <div class="" style="padding:20px 10px;min-height: 460px; overflow: hidden;">
                                
                                <p class="min-order">Minimum order quantity:{{x.minorderqty}} tonne<span v-if="x.minorderqty > 1">s</span></p>

                                <!-- <a :href="'/product-details/'+x.itemID"> -->
                                <a href="#!">
                                    <img :src="x.image" class="product-img" v-if="x.image" />   
                                    <img src="https://www.caretastic.in/upload/productimg/imagenotfound.jpg" class="product-img" v-else />
                                </a>

                                <p class="bolder text-ellipsis">
                                    <span>{{x.productname}}</span>
                                </p>

                                <!-- ({{ thDFormat(Number(x.netweight))}}Kg) -->

                                <p> <span class="color-scope bolder">
                                    &#x20A6;{{ thDFormat(Number(x.price)) }}</span> 
                                    <span class="font-12"> (Per {{unitFormatter(x.unit)}}) </span> 
                                    
                                    <br> 

                                    <!-- .slice(0, -1) -->
                                    <small class="font-10">{{x.bagcount}} {{unitFormatter(x.unit)}}s per Tonne</small>

                                </p>


                                <div :id="'showE'+x.itemID" v-if="!cartitem.includes(x.itemID)">
                                    <small>Enter quantity in tonnes </small><br>
                                    <input  value="" type="number" oninput="this.value < 1 ? this.value = 0 : this.value" class="input-control" style="width: 180px; margin: 0 5px 5px; text-align:center;outline: none;border: 1px solid #006e4038;" :id="'item2_'+x.itemID">
                                </div>
                                    

                                <span v-if="!cartitem.includes(x.itemID)">
                                    <button class="btn bg-secondary shadow border bolder col-8 offset-2 rounded-pill font-12 add-cart-btn p-2 bold" @click="addToCart(x.itemID)">
                                        ADD TO CART
                                    </button>
                                </span>

                                <span v-else style="bottom: 10px; position: absolute; right: 0; left: 0;">
                                    <button class="rounded-pill btn-sm btn bg-scope text-white shadow" @click="decrement(x.itemID)">
                                        <i class="fa fa-minus bold"></i>
                                    </button>

                                    <input  :value="unitCount(x.itemID)"  v-on:keyup="setUnit(x.itemID)" type="tel" oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1');" class="input-control" style="width: 100px; margin: 0 5px; text-align:center;outline: none;border: 1px solid #f2f2f2;"  :id="'item_'+x.itemID">
                                    
                                    <button class="rounded-pill btn-sm btn bg-scope text-white shadow" @click="increment(x.itemID)">
                                        <i class="fa fa-plus bold"></i>
                                    </button>

                                    <br><br>

                                    <span class="font-14">{{thDFormat(unitCount(x.itemID) * x.bagcount) }} {{unitFormatter(x.unit)}}s in {{unitCount(x.itemID)}} tonnes </span>
                                </span>
                            </div>
                        </div>
                    </div>

                    <div class="col-12">
                        <div class="mt-4 text-center"><a href="/checkout" class="btn checkout-btn">PROCEED TO CHECKOUT</a></div>
                    </div>
                </div>

                <div v-else-if="pageloader == 0">
                    <div class="p-5 text-center">
                        <img src="@/assets/images/loading.jpg" style="width: 30px; margin-top:120px" />
                    </div>
                </div>

                <div v-else class="row text-center">
                    <div class="p-5">
                        <img src="@/assets/images/product_not_found.jpg" style="width: 250px;" />
                        <p class="mt-5">Products unavailable for this category</p>
                    </div>
                </div>

                <Copyright />
            </div>
        </div>
    </div>
</template>


<script>
import SideNav from '@/components/SideNav.vue';
import Copyright from '@/components/Copyright.vue';
import TopNav from '@/components/TopNav.vue';
import axios from 'axios';



export default {
    name: "Products",
    components: {
        SideNav, TopNav, Copyright
    },
    
    data() {
        return{
            page_active:"products",
            obj_product:{
                "type":'all'
            },
            product_list:[],

            all_product_list:[],
            product_category:[],
            pageloader:0,
            
            sub_acct:'',
            product_data:{},
            location:'',
            product_search:''
        }
    },

    watch: {
        $route: {
            immediate: true,
            handler() {
                document.title = "FMN | Products";
            },
        },
    },
        
    computed: {
        cartitem() {
            return this.$store.getters.cart;
        },        
        cartarray (){
            return this.$store.getters.cartdata;
        }
    },
    
    mounted () {
        
        this.$swal.fire({title: "", html: this.$preloader, showConfirmButton: false, showCancelButton: false}); // preloader

        this.sub_acct       = this.$store.getters.sub_acct;  
        this.location       = this.$store.getters.collectionarea;  
        this.loadProduct();
    },

    methods: {
        async loadProduct () {
            try {
                this.obj_product.location = this.location;
                this.obj_product.subaccountID = this.sub_acct;

                console.log(this.$store.getters.headerstoken)
                
                await axios({
                    method: "get",
                    url: this.$hostname+"/api/productcatalogueselfcollection",
                    params: this.obj_product,
                    headers: { "Content-Type": "multipart/form-data",
                            "Authorization" : `Bearer ${this.$store.getters.headerstoken}`  },
                })

                .then(res => {
                    
                    this.$swal.close();
                    
                    this.pageloader   = 1;
                    let product_lists = res.data.data;

                    let result = product_lists.reduce(function (r, a) {
                        r[a.category] = r[a.category] || [];
                        r[a.category].push(a);
                        return r;
                    }, Object.create(null));


                    let subacct_code  = this.sub_acct.split('-')[1];
                    let business_rules = subacct_code.charAt(0)


                    if(business_rules == 'B'){
                        this.product_category =  Object.keys(result).filter( el => el == 'Flour' );
                        this.product_data = result;           
                        
                        this.all_product_list = product_lists.filter( el => el.category == 'Flour' ); 

                        this.filterProduct("Flour");
                    }

                    else if(business_rules == 'C'){
                        document.querySelector('#all_category').style.display = "block";
                        this.product_category = Object.keys(result).filter( el => el !== 'Flour' );
                        this.product_data = result;
                        
                        this.all_product_list = product_lists.filter( el => el.category !== 'Flour' ); 
                        this.filterProduct("all");
                    }
                    
                    else{
                        this.product_data = [];
                        this.product_category = [];
                        // alert("No business rules found on account!");
                        
                        this.$swal.fire(
                                'Success Alert',
                                "No business rules found on account!",
                                'success'
                            )
                    }

                    

                })

                .catch(error => {
                    console.log(error)
                })  

                .finally(e => {                 
                    console.log(e);
                })
            }
            catch (err) {
                console.log(err)
            }            
        },

        filterProduct(x){
            let key = 'itemID';

            if(x == 'all'){
                this.obj_product.type = 'all';
                let product_list = this.all_product_list;
                
                let arrayUniqueByKey = [...new Map(product_list.map(item =>
                [item[key], item])).values()];

                this.product_list = arrayUniqueByKey;
            }
            else{
                // this.$store.commit("set_selected_category", x);
                this.obj_product.type = x;
                let product_list = this.product_data[x];

                let arrayUniqueByKey = [...new Map(product_list.map(item =>
                [item[key], item])).values()];

                this.product_list = arrayUniqueByKey;
            }
        },

        addToCart(x){
            let data = this.cartitem;
            let cartarr = this.cartarray;

            
            let preset_unit = document.getElementById('item2_'+x).value;

            document.getElementById('showE'+x).style.display = 'none';

            let unit = preset_unit == '' ? 1 : preset_unit == 0 ? 1 : preset_unit;

            let cartdata = {"itemID":x,  "unit":unit};

            data.push(x);
            cartarr.push(cartdata);

            this.$store.commit("updateCart", data);
            this.$store.commit("updateCartdata", cartarr);
        },

        removeFromCart(x){
            const index = this.cartitem.indexOf(x);
            let data = this.cartitem;
            if (index > -1) {data.splice(index, 1);}

            
            let cartarr = this.cartarray.filter( el => el.itemID !== x ); 


            this.$store.commit("updateCart", data);
            this.$store.commit("updateCartdata", cartarr);
        },

        increment(x){
            let input  = Number(document.getElementById('item_'+x).value) + 1;
            document.getElementById('item_'+x).value = input;
            this.updateCartQuantity (input, x, '+');
        },

        setUnit(x){
            let input = Number(document.getElementById('item_'+x).value);
            input > 0 ? document.getElementById('item_'+x).value = input : this.removeFromCart(x);            
            this.updateCartQuantity (input, x, '');
        },

        decrement(x){
            let input = Number(document.getElementById('item_'+x).value) - 1;
            input > 0 ? document.getElementById('item_'+x).value = input : this.removeFromCart(x);            
            this.updateCartQuantity (input, x, '-');
        },

        updateCartQuantity (value, product_id, action) {
            let cart_array = this.$store.getters.cartdata;
            cart_array.forEach(i => {
                if (product_id == i.itemID) {
                    if (action == '+') {
                        i.unit = parseInt(i.unit) + 1;
                    }
                    else if (action == '-') {
                        i.unit = parseInt(i.unit) - 1;
                    }
                    else {
                        i.unit = value;
                    }
                }
            })  

            this.$store.commit("updateCartdata", cart_array);
        },        

        unitCount(x){
            return this.cartarray.filter( el => el.itemID == x )[0].unit;
        },

        searchProduct(keyword){
            let subacct_code  = this.sub_acct.split('-')[1];
            let business_rules = subacct_code.charAt(0)
            if(business_rules !== 'B'){
                this.filterProduct('all');
            }


            let key = 'itemID';
            let product_list = this.all_product_list;
            
            let arrayUniqueByKey = [...new Map(product_list.map(item => [item[key], item])).values()];

            let product_group = arrayUniqueByKey;
            

            this.product_list = product_group.filter((data) =>data.itemID.includes(keyword) || data.productname.toLowerCase().includes(keyword.toLowerCase()))
        },

        unitFormatter(x){
            // 
            let lastChar = x.substr(x.length - 1)

            if(x == 'gal'){
                return 'Gallon';
            }
            else{
                if(lastChar == 's'){
                    return x.slice(0, -1);
                }
                else{
                    return x;
                }
            }
            
        }
    },
    
};
</script>

<style scoped>
.shadow-hover:hover {background: #FFFFFF; border: 1px solid rgba(0, 0, 0, 0.1); box-sizing: border-box; border-radius: 1px 1px 5px 5px; box-shadow: inset 0px -9px 0px 0px rgb(0 110 64) !important;}
.shadow-hover:hover{box-shadow: 0px 40px 40px rgba(0, 0, 0, 0.1);}
.add-cart-btn{ display: block;transition: 1s all;  -webkit-transition: 1s all; -moz-transition: 1s all;  -o-transition: 1s all; -ms-transition: 1s all; }
.shadow-hover:hover .add-cart-btn{display: block; transition: 1s all;  -webkit-transition: 1s all; -moz-transition: 1s all;  -o-transition: 1s all; -ms-transition: 1s all; }
.nav-link{font-weight: 600;color: #000 !important;padding: 10px 30px;}
.nav-link.active{ background: #006E40; color: #fefefe !important;}
.nav-item{margin-right: 10px;}
/* .nav-tabs {border-bottom: 1px solid #dee2e638;} */
.product-img{ height:220px}

.shadow-hover.col-lg-3{
    width: calc(25% - 30px);
    padding: 0;
    margin: 0 15px;

}

.min-order {
    position: absolute;
    top: 0;
    right: 0;
    background: green;
    color: white;
    font-size: 11px;
    padding: 5px;
    z-index: 200;
    border-radius: 0 0 0 5px;
}
</style>